import React, { useEffect, useState } from 'react';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext'; 
import { usePageLoadContext } from '../context/PageLoadContext';
import { useControlContext } from '../context/ControlContext';
import { Input } from '@progress/kendo-react-inputs';
import { findFolderById } from '../services/shared';
import { FoldersModel } from '../models/foldersModel';
import { useFoldersContext } from '../context/FoldersContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { isMobile } from 'react-device-detect';
import PagePrev from '../assets/PagePrevIcon.svg';
import PageNext from '../assets/PageNextIcon.svg';
import { showMessage } from '../services/languages/_showmessages';

const PageNavigatorBox = () => {
  const { Folders } = useFoldersContext();
  const { Folder, setFolder } = useFolderContext(); 
  const { Page, setPage } = usePageContext();     
  const { setPageLoad } = usePageLoadContext();
  const [ pageText, setPageText ] = useState<string>(Page?.toString() ?? '0');
  const { setMessageBox } = useMessageBoxContext();
  const { commandControlContext, setCommandControlContext } = useControlContext();
  
  useEffect(() => {
    setPageText(Page?.toString() ?? '0')
  }, [Page]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {  
      setPage(parseInt(pageText));
      // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
      setTimeout(() => {
        setPageLoad(true);
      }, 100);
    }
  }

  // Recua uma página
  const pagePrevious = () => {
    let page = Page ?? 0;
    page--;
    
    if (page < 1) {
      // Volta uma pasta
      const folderPrevious = (Folder?.Id ?? 0) - 1
      if (folderPrevious < 1) {
        // Primeira pasta
        return;
      }

      let folder: FoldersModel | null = findFolderById(Folders, folderPrevious);
      setFolder(folder);
      page = folder?.Pages ?? 0
    }

    setPageText(page.toString());
    setPage(page);

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo do PageContext ser atualizado.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);    
  };

  // Hook para voltar uma página a partir de outros componentes
  useEffect(() => {
    if (!commandControlContext?.pagePrevious) return;

    setCommandControlContext({ pagePrevious: false});
    pagePrevious();
  }, [commandControlContext?.pagePrevious]);

  // Avança uma página
  const pageNext = () => {      
    let page = Page ?? 0;
    page++;
    
    if (page > (Folder?.Pages ?? 0)) {
      // Avança uma pasta
      const folderNext = (Folder?.Id ?? 0) + 1      
      let folder: FoldersModel | null = findFolderById(Folders, folderNext);
      if (folder === null) {
        // Última pasta
        return;
      }
      
      setFolder(folder);
      page = (folder?.Pages === 0 ? 0 : 1);
    }

    setPageText(page.toString());
    setPage(page);

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  };

  // Hook para avançar a página a partir de outros componentes
  useEffect(() => {
    if (!commandControlContext?.pageNext) return;

    setCommandControlContext({ pageNext: false});
    pageNext();
  }, [commandControlContext?.pageNext]);

  const onChange = (e: any) => {
    const re = /^[0-9\b]+$/;                                  
    const page : string = e.target.value === undefined ? '' : e.target.value.toString();
    if (page === '' || re.test(page)) {
      if (page === '') {
        setPageText('');
        return;
      }
      let PageTotal = (Folder?.Pages === undefined ? 0 : Folder?.Pages);                                                
      if (parseInt(page) <= PageTotal) {
        setPageText(page);

        // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
        setTimeout(() => {
          setPageLoad(true);
        }, 100);
      }
    }
  };

  return (
    <>
      {isMobile ? 
        <div className='pagenavigationbox-mobile'>
          <div>
            <small className='pagetitle-mobile'>{showMessage("paginas")}</small>
          </div>
          <img src={PagePrev} className='pageprev-mobile' alt="previous" onClick={() => pagePrevious()} />

          <Input onKeyDown={handleKeyDown} className='pagenavigationinput-mobile' value={pageText} onChange={(e) => onChange(e)}/><span className='pagenavigationtotal'>/{(Folder?.Pages === undefined ? 0 : Folder?.Pages)}&nbsp;</span>

          <img src={PageNext} className='pagenext-mobile' alt="next" onClick={() => pageNext()} />
        </div>
      :
        <div className='pagenavigationbox'>                
          <img src={PagePrev} className='pageprev' alt="previous" onClick={() => pagePrevious()} />

          <Input onKeyDown={handleKeyDown} className='pagenavigationinput' value={pageText} onChange={(e) => onChange(e)}/><span className='pagenavigationtotal'>/{(Folder?.Pages === undefined ? 0 : Folder?.Pages)}&nbsp;</span>

          <img src={PageNext} className='pagenext' alt="next" onClick={() => pageNext()} />
        </div>
      }
    </>
  );
};

export default PageNavigatorBox;